<template>
	<div class="home">
		<v-card color="basil" outlined>
			<v-card-title class="text-center justify-center py-6">
				<img src="@/assets/mee.png" :width="wWidth / 8" />
				<h1 class="font-weight-bold text-h2 basil--text">Yukino Aoki</h1>
			</v-card-title>
		</v-card>

		<v-tabs v-model="tab" background-color="transparent" color="basil" grow>
			<v-tab href="#tab-1">Basic information</v-tab>
			<v-tab href="#tab-2">project</v-tab>
			<v-tab href="#tab-3">research</v-tab>
			<v-tab href="#tab-4">LIKE</v-tab>

			<v-tab-item value="tab-1" class="basil">
				<v-card class="basil py-5" flat>
					<v-row class="mx-5">
						<v-col cols="4" class="container" align="center">
							<v-card class="container rounded-tl-xl">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
										>
											CURRENT</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>明治大学大学院</v-card-subtitle
										>
										<v-card-text
											>先端数理科学研究科<br />先端メディアサイエンス専攻
											<br />修士2年</v-card-text
										>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>
						<v-col cols="4" class="container" align="center">
							<v-card class="container">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>LIKE</v-card-title
										>
										<v-card-subtitle class="justify-center text-h6 py-0"
											>多趣味！</v-card-subtitle
										>
										<v-card-text
											>運動、映画、服、ネイル、<br />イラスト、器用（え?）<br />自分でものを作ることが好き</v-card-text
										>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>

						<v-col cols="4" class="container" align="center">
							<v-card class="container rounded-tr-xl">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>COMICS</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>少年漫画</v-card-subtitle
										>
										<v-card-text>
											HUNTER×HUNTER、呪術廻戦、
											<br />鋼の錬金術師、進撃の巨人、 <br />書ききれない！！！
										</v-card-text>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>

						<v-col cols="4" class="container" align="center">
							<v-card class="container">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>TRAVEL</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>海外旅行</v-card-subtitle
										>
										<v-card-text
											>🇺🇸🇮🇹🇨🇦🇫🇷🇬🇷🇬🇺🇰🇷🇭🇷🇲🇪🇹🇼🇮🇪<br />
											右の写真はN.Y.に行った時の！<br />新年のカウントダウン感動した</v-card-text
										>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>
						<v-col cols="4" class="container" align="center">
							<v-card class="basil container pa-0" flat>
								<div
									class="d-flex flex-no-wrap justify-space-between container"
								>
									<v-avatar class="rounded container" :size="wWidth / 4" tile>
										<v-img
											:aspect-ratio="1 / 1"
											src="@/assets/自己紹介.jpg"
										></v-img>
									</v-avatar>
								</div>
							</v-card>
						</v-col>
						<v-col cols="4" class="container" align="center">
							<v-card class="container">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>NAME</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>青木由樹乃</v-card-subtitle
										>
										<v-card-text>aoki yukino</v-card-text>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>
						<v-col cols="4" class="container" align="center">
							<v-card class="container rounded-bl-xl">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>CHARACTER</v-card-title
										>

										<v-card-subtitle class="text-h6 py-0"
											>楽観主義</v-card-subtitle
										>
										<v-card-text>よく笑う！ゲラ！</v-card-text>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>

						<v-col cols="4" class="container" align="center">
							<v-card class="container">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>CLUB</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>バレーボール</v-card-subtitle
										>
										<v-card-text class="justify-cente"
											>中学高校6年間</v-card-text
										>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>
						<v-col cols="4" class="container" align="center">
							<v-card class="container rounded-br-xl">
								<div>
									<div>
										<v-card-title
											class="justify-center font-weight-bold basil--text"
											>LABORATORY</v-card-title
										>

										<v-card-subtitle class="justify-center text-h6 py-0"
											>中村聡史研究室</v-card-subtitle
										>
										<v-card-text class="justify-cente"
											>ヒトとコンピュータのインタラクション</v-card-text
										>
									</div>

									<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-tab-item>

			<v-tab-item value="tab-2" class="basil">
				<v-card class="basil pa-10" flat>
					<v-row>
						<v-card class="rounded-t-xl container" align="center">
							<div>
								<div>
									<v-card-title
										class="my-2 justify-center font-weight-bold basil--text text-h4"
										>BingoFit</v-card-title
									>

									<v-card-subtitle class="justify-center text-h7 py-0"
										>ビンゴとコーディネートを掛け合わせた衣服提示システム</v-card-subtitle
									>

									<v-card-text class="mt-5 grey--text"
										><v-btn outlined color="lime darken-3" @click="bingofit()"
											>デモ版</v-btn
										>
										<br />※スマートフォン推奨
									</v-card-text>
									<v-card-actions class="justify-center">
										<!-- <v-btn color="orange lighten-2" text> Explore </v-btn> -->

										<v-btn icon @click="show1 = !show1">
											<v-icon>{{
												show1 ? "mdi-chevron-up" : "mdi-chevron-down"
											}}</v-icon>
										</v-btn>
									</v-card-actions>

									<v-expand-transition>
										<div v-show="show1">
											<v-divider></v-divider>
											<v-card-text
												class="mt-10 justify-center font-weight-bold basil--text"
											>
												制作背景
											</v-card-text>
											<v-card-text>
												皆さんはついついいつも同じコーディネートをしてしまったり、<br />クローゼットの奥にしまわれた衣服の存在を忘れてしまったりすることはありませんか？<br />
												私は約300着の衣服を所有しているのですが、その中にはお気に入りで頻繁に着用する衣服もあれば、<br />存在を忘れてタンスの肥やしになっている服もあり、衣服を活用できていないと感じます。<br />
												そこで所有する衣服の着用回数の偏りに着目し、ビンゴゲームの要素を取り入れた衣服提示をすることで、<br />衣服の活用を促進させるシステムBingoFitを制作しました。
											</v-card-text>
											<v-card-text
												class="mt-10 justify-center font-weight-bold basil--text"
											>
												デザインの意図
											</v-card-text>
											<v-card-text>
												25着の服をビンゴのデザインを取り入れた提示することで、リーチになっている列の服を着ようとしたり、<br />ビンゴを作るために同じ列にある服でコーディネートができるか考えてみたり、今までと異なる衣服の選択が期待されます。<br />利用する中で、所有する衣服でのコーディネートの幅が広がり、<br />ユーザがもっとファッションを楽しめるようなシステムデザインとなっています。</v-card-text
											>
											<v-card-text class="my-5">
												詳細や実際の研究成果はRESEARCHをご覧ください</v-card-text
											>
										</div>
									</v-expand-transition>
								</div>
							</div>
						</v-card>
					</v-row>
					<v-row class="py-5">
						<v-card class="container" align="center">
							<div>
								<div>
									<v-card-title
										class="my-2 justify-center font-weight-bold basil--text text-h4"
										>メロ字ィ</v-card-title
									>

									<v-card-subtitle class="justify-center text-h8 py-0"
										>漢字を奏でる漢字習得支援システム</v-card-subtitle
									>
									<v-card-text class="mt-5 grey--text"
										><v-btn outlined color="lime darken-3" @click="melojee()"
											>デモ版</v-btn
										>
										<br />※iPad推奨
									</v-card-text>

									<v-card-actions class="justify-center">
										<!-- <v-btn color="orange lighten-2" text> Explore </v-btn> -->

										<v-btn icon @click="show2 = !show2">
											<v-icon>{{
												show2 ? "mdi-chevron-up" : "mdi-chevron-down"
											}}</v-icon>
										</v-btn>
									</v-card-actions>

									<v-expand-transition>
										<div v-show="show2">
											<v-divider></v-divider>
											<v-card-text
												class="mt-10 justify-center font-weight-bold basil--text"
											>
												制作背景
											</v-card-text>
											<v-card-text>
												文字を覚えたり、美しい文字を習得したりする方法としては繰り返し書いて練習することが一般的です。<br />しかしそうした方法は単純作業になりやすく、退屈に感じやすいです。<br />そこで文字練習を楽しくかつ、効果的なものにするために、<br />ペンが置かれている位置に応じて音を鳴らすシステム"メロ字ィ"を制作しました。<br /><br />このシステムは研究室4名でのハッカソンで作成しました。
											</v-card-text>
										</div>
									</v-expand-transition>
								</div>

								<!-- <v-avatar class="ma-3" size="125" tile>
										<v-img :src="item.src"></v-img>
									</v-avatar> -->
							</div>
						</v-card>
					</v-row>
					<v-row>
						<v-card class="container rounded-b-xl" align="center">
							<div>
								<div>
									<v-card-title
										class="my-2 justify-center font-weight-bold basil--text text-h4"
										>HomeMee
									</v-card-title>
									<v-card-subtitle class="justify-center text-h8 py-0">
										褒めて褒められ自己肯定感爆あがりゲーム
									</v-card-subtitle>
									<v-card-text class="mt-5"
										><v-btn outlined color="lime darken-3" @click="homemee()"
											>デモ版</v-btn
										>
									</v-card-text>
									<v-card-actions class="justify-center">
										<!-- <v-btn color="orange lighten-2" text> Explore </v-btn> -->

										<v-btn icon @click="show3 = !show3">
											<v-icon>{{
												show3 ? "mdi-chevron-up" : "mdi-chevron-down"
											}}</v-icon>
										</v-btn>
									</v-card-actions>

									<v-expand-transition>
										<div v-show="show3">
											<v-divider></v-divider>
											<v-card-text
												class="mt-10 justify-center font-weight-bold basil--text"
											>
												制作背景
											</v-card-text>
											<v-card-text>
												みなさん、褒められると嬉しいですよね？<br />
												他人に褒められることで、自己肯定感が上がってやる気が出たり、自分の新しい一面に気付けたりと、メリットばかりです。<br />
												しかし、わざわざ面と向かって人を褒めるのは少し恥ずかしくて躊躇ってしまうこともあると思います。<br />また、せっかく褒められてもその言葉を覚えていなかったり、さらっと聞き流してしまったり、、<br />たくさん人を褒めることはとても良いことで、それをきっかけに人間関係が深まる、なんてことがあるかもしれません。<br />
												そこで褒めること、そして褒められることをゲーム化することで、<br />褒める行為・褒められる行為をより楽しむアプリ「HomeMee」を開発しました！
												<br /><br />このシステムは研究室5名でのハッカソンで作成しました。
												<br />現段階では、5名専用でgoogleアカウントが必須となっているため、<br />詳細は<a
													v-bind:href="homemeeURL"
													>こちらのリンク</a
												>にあるデモ動画を参照してください。
											</v-card-text>
										</div>
									</v-expand-transition>
								</div>
							</div>
						</v-card>
					</v-row>
				</v-card>
			</v-tab-item>

			<v-tab-item value="tab-3">
				<v-card class="basil py-5" flat>
					<!-- <v-card-title class="justify-center font-weight-bold basil--text">
						過去に学会発表を行った研究たちです〜</v-card-title
					> -->
					<v-row class="mx-5">
						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-tl-xl">
								<div>
									<div>
										<v-card-title class="justify-center">
											HCI International 2023</v-card-title
										>

										<v-card-subtitle
											class="justify-center text-h6 py-2 basil--text font-weight-bold"
											>BingoFit: A Bingo Clothes Presentation System for
											Utilizing Owned Clothes</v-card-subtitle
										>

										<v-card-text>
											7月にデンマークで発表予定です。詳細は後日〜
										</v-card-text>
									</div>
								</div>
							</v-card>
						</v-col>
						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-tr-xl">
								<div>
									<div>
										<v-card-title class="justify-center">
											第199回HCI研究会</v-card-title
										>

										<v-card-subtitle
											class="justify-center text-h6 py-2 basil--text font-weight-bold"
											>BingoFit:
											所有する衣服の活用に向けたビンゴ型衣服提示システムの改良と検証</v-card-subtitle
										>

										<v-card-text>
											詳細は<a v-bind:href="bingo2">こちら</a>をご参照ください。
										</v-card-text>
									</div>
								</div>
							</v-card>
						</v-col>
						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-bl-xl">
								<div>
									<div>
										<v-card-title class="justify-center"
											>第195回HCI研究会</v-card-title
										>

										<v-card-subtitle
											class="justify-center text-h6 py-2 basil--text font-weight-bold"
											>BingoFit:
											所有する衣服の活用に向けたビンゴゲーム型衣服提示システムの提案</v-card-subtitle
										>
										<v-card-text>
											詳細は<a v-bind:href="bingo3">こちら</a>をご参照ください。
										</v-card-text>
									</div>
								</div>
							</v-card>
						</v-col>
						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-br-xl">
								<div>
									<div>
										<v-card-title class="justify-center"
											>第190回HCI研究会</v-card-title
										>

										<v-card-subtitle
											class="justify-center text-h6 py-2 basil--text font-weight-bold"
											>多人数での手書き環境において文字の綺麗さが与える影響の調査</v-card-subtitle
										>
										<v-card-text>
											<!-- こちらは株式会社ワコム様との共同研究です。<br /> -->
											詳細は<a v-bind:href="tegaki1">こちら</a
											>をご参照ください。
										</v-card-text>
									</div>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-tab-item>

			<v-tab-item value="tab-4">
				<v-card class="basil py-5" flat>
					<v-row class="mx-5">
						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-tl-xl">
								<div>
									<v-card-title
										class="justify-center font-weight-bold basil--text"
									>
										SELF NAIL</v-card-title
									>

									<v-card-text>めちゃくちゃ時間溶かしてます🎨</v-card-text>
									<v-carousel
										hide-delimiters
										justify-center
										cycle
										interval="3000"
										show-arrows-on-hover
										:height="wWidth / 3"
									>
										<v-carousel-item v-for="(nail, i) in nails" :key="i">
											<v-img
												:width="wWidth / 3"
												:aspect-ratio="1 / 1"
												:src="nail"
											></v-img>
										</v-carousel-item>
									</v-carousel>
								</div>
							</v-card>
						</v-col>

						<v-col cols="6" class="container" align="center">
							<v-card class="container rounded-tr-xl">
								<div>
									<v-card-title
										class="justify-center font-weight-bold basil--text"
									>
										ILLUSTRATION</v-card-title
									>

									<v-card-text
										>ゆるいお絵描き載ってるアカウント
										<v-icon color="purple" @click="goInsta">
											mdi-instagram
										</v-icon>
									</v-card-text>
									<v-carousel
										hide-delimiters
										cycle
										interval="3000"
										show-arrows-on-hover
										:height="wWidth / 3"
									>
										<v-carousel-item v-for="(oekaki, i) in oekaki" :key="i">
											<v-img
												:width="wWidth / 3"
												:aspect-ratio="1 / 1"
												:src="oekaki"
											></v-img>
										</v-carousel-item>
									</v-carousel>
								</div>
							</v-card>
						</v-col>
					</v-row>
				</v-card>
			</v-tab-item>
		</v-tabs>
	</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// import { Router } from "express";

export default {
	name: "Home",
	// components: home,
	data() {
		return {
			wWidth: window.innerWidth, //ウィンドウサイズ
			wHeight: window.innerWidth,
			tab: null,
			show1: false,
			show2: false,
			show3: false,

			homemeeURL: "https://nkmr-lab.org/news/homemee.html",
			bingo1: "https://dl.nkmr-lab.org/papers/288",
			bingo2: "https://dl.nkmr-lab.org/papers/395",
			bingo3: "https://dl.nkmr-lab.org/papers/351",
			tegaki1: "https://dl.nkmr-lab.org/papers/288",
			items: ["Basic info", "project", "Deserts", "Cocktails"],
			nails: [
				require("@/assets/nail/1.jpg"),
				require("@/assets/nail/2.jpg"),
				require("@/assets/nail/3.jpg"),
				require("@/assets/nail/4.jpg"),
				require("@/assets/nail/5.jpg"),
				require("@/assets/nail/6.jpg"),
				require("@/assets/nail/7.jpg"),
				require("@/assets/nail/8.jpg"),
				require("@/assets/nail/9.jpg"),
				require("@/assets/nail/10.jpg"),
				require("@/assets/nail/11.jpg"),
				require("@/assets/nail/12.jpg"),
				require("@/assets/nail/13.jpg"),
			],
			oekaki: [
				require("@/assets/illust/1.png"),
				require("@/assets/illust/2.png"),
				require("@/assets/illust/3.png"),
			],
		};
	},
	methods: {
		bingofit() {
			window.open("https://aoki.nkmr.io/aoki_bingo_test/#/", "_blank");
			// Router.push("/aoki_bingo_test/#/);
		},
		homemee() {
			window.open("https://ueki.nkmr.io/homemee/#/", "_blank");
			// Router.push("/aoki_bingo_test/#/);
		},
		melojee() {
			window.open("https://yokoyama.nkmr.io/melo-jee/#/", "_blank");
			// Router.push("/aoki_bingo_test/#/);
		},
		goInsta() {
			window.open("https://www.instagram.com/oekakishita/", "_blank");
		},
		// img(num) {
		// 	require(`@/assets/nail/${num+1}.jpg`);
		// },
		handleResize: function () {
			// ウインドウサイズが変わるたびにサイズを代入
			this.wWidth = window.innerWidth;
			this.wHeight = window.innerHeight;
			if (this.wWidth > this.wHeight) {
				this.wHeight = window.innerWidth;
			}
		},
	},
};
</script>

<style>
/* Helper classes */
.basil {
	background-color: #fffbe6 !important;
}
.basil--text {
	color: #356859 !important;
}
.grey--text {
	color: #3c3c3c !important;
}

.fullscreen {
	/* position: fixed; */
	width: 100%;
	height: 100%;
}
v-img {
	/* position: fixed; */
	/* width: 33%; */
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
}
.home {
	/* height: 100vh; */
	background: #fffbe6;
	min-height: 100%;
}
.container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.auto {
	width: 33vw;
	height: auto;
	/* object-fit: contain; */
}
</style>
